import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from './common/header'
import { default as Footer } from './common/footer'
import { default as Contact } from './homepage/Contact'
import { all } from 'q';

const BlogList = () => {
    // const { allMarkdownRemark } = data
    // const { currentPage, numPages } = pageContext

    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark (
                    sort: { fields: [frontmatter___date], order: DESC }
                ) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                date(formatString: "MMMM DD, YYYY")
                                author
                                avatar
                                category
                                image
                            }
                        }
                    }
                }
            }
        `
    )

    return(
        <section className={styles.Container}>
            <Header />
            <div className={styles.BlogPosts}>
                <div className={styles.Centered}>
                    <header>
                        <h1>Insights</h1>
                        <h2>
                            Learnings, best practices and insights on product
                            design & development, innovation, apps, software,
                            and more.
                        </h2>
                    </header>

                    <section className={styles.BlogPostsGrid}>
                        {allMarkdownRemark.edges.map((item: any) => {
                            const { frontmatter } = item.node

                            return (
                                <div className={styles.BlogPost}>
                                    <a href={item.node.fields.slug}>
                                        <div className={styles.BlogPostAvatar}>
                                            {frontmatter.image && (
                                                <img src={frontmatter.image} alt={frontmatter.title} />
                                            )}
                                        </div>
                                        <h2>{item.node.frontmatter.title}</h2>
                                        <p className={styles.BlogPostMeta}>
                                            <img alt={frontmatter.author}
                                                style={{width: '20px', height: '20px', borderRadius: '100%', marginRight: '16px'}}
                                                src={frontmatter.avatar} />
                                            <strong>{frontmatter.author} </strong> | {frontmatter.date}
                                        </p>
                                    </a>
                                </div>
                            )
                        })}
                    </section>
                </div>
            </div>
            <Contact />
            <Footer />
        </section>
    )
}

export default BlogList